import React, { useEffect, useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import MyToast from "../components/UI/toast/MyToast";
import { useSearchParams, Link } from "react-router-dom";
import axios from "axios";
import { API_AUTH_URL } from "../config";
import MyLoader from "../components/UI/loader/MyLoader";
import { ORGANIZATION_NAME } from "../config";


const EmailVerify = () => {
  const [params, setParams] = useSearchParams("abc");
  const [loading, setLoading] = useState(false);
  const dataFetchedRef = useRef(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Регистрация | " + ORGANIZATION_NAME
  }, [])
  
  const handleError = (t_error) => {
    setError(t_error);
  };
  
  const closeAlert = () => {
    setError(null);
  };

  const sendFooBar = async () => {
    setLoading(true);
    await axios({
      method: "GET",
      url: `${API_AUTH_URL}/api/v1/register/?destination=${params
        .get("destination")
        .replace("%3D", "=")}&code=${params.get("code").replace("%3D", "=")}`,
    })
      .then((res) => {
        setLoading(false);
      })
      .catch((e) => {
        setError(e.response.data);
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    sendFooBar();
  }, [params]);

  return (
    <>
      <CSSTransition
        in={error}
        timeout={300}
        classNames="wrap"
        unmountOnExit
        mountOnEnter
      >
        <div className="wrap">
          {error ? (
            <MyToast error={error} closeAlert={closeAlert} />
          ) : (
            <MyToast error={""} closeAlert={closeAlert} />
          )}
        </div>
      </CSSTransition>
      <div className="height-need">
        <div className="main2">
          {loading ? (
            <MyLoader />
          ) : (
            <div className="two-sides-flex">
              {error ? (
                "Произошла ошибка"
              ) : (
                <div>
                  Ваша почта подтверждена, дождитесь верификации аккаунта администратором!{" "}<></>
                  <Link to="/login">войти</Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
