import React, { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";
import CalendarContext from "../../context/CalendarContext";

const WeekDay = ({
    day,
    rowIdx,
    setCalendarType,
    dayClick,
    makeDetailed,
    setEventToDetail,
}) => {
    const timestamps = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
    ];
    const [dayEvents, setDayEvents] = useState([]);
    const { events, setEvents } = useContext(CalendarContext);
    const [isHoverEvent, setHoverEvent] = useState(false);

    const handleMouseEnter = () => {
        setHoverEvent(true);
     };
  
     const handleMouseLeave = () => {
        setHoverEvent(false);
     };

    useEffect(() => {
        let thisDayEvents = events.filter((event) =>
            dayjs(day.format("YYYY-MM-DD")).isBetween(
                dayjs(event.date_start),
                dayjs(event.date_end),
                "day",
                "[]"
            )
        );
        thisDayEvents.map((item, index) => {
            item.alignment = index + 1;
        });

        const eventsByHour = {};
        thisDayEvents.forEach(event => {
            const startHour = dayjs(event.date_start).hour();
            if (!eventsByHour[startHour]) {
                eventsByHour[startHour] = [];
            }
            eventsByHour[startHour].push(event);
        });

        Object.values(eventsByHour).forEach(eventsInHour => {
            const numIntersections = eventsInHour.length;
            if (numIntersections > 1) {
                const columnWidth = 100 / numIntersections; // Assuming 100% width for the day column
                eventsInHour.forEach((event, index) => {
                    event.width = columnWidth - 2;
                    event.leftOffset = index * columnWidth + 1;
                });
            } else {
                eventsInHour[0].width = 98;
                eventsInHour[0].leftOffset = 1;
            }
        });

        setDayEvents(thisDayEvents);
    }, [events, day]);

    const checkForZero = (number) => {
        if (number[0] === "0") {
            return number[1];
        } else {
            return number;
        }
    };

    const timeToNumber = (time) => {
        return (
            Number(checkForZero(time.slice(0, 2))) * 60 +
            Number(time.slice(3, 5))
        );
    };

    const setDetailedEvent = (event) => {
        setEventToDetail(event);
        makeDetailed();
    };

    return (
        <div className="week-day-body">
            <div className="week-day-timestamps">
                {timestamps.map((item, i) => (
                    <div className="week-day-one-chunck" key={i}></div>
                ))}
            </div>
            {dayEvents.map((event, i) => (
                <div
                    className="week-day-one-event"
                    style={{
                        background: event.module.color_code + "66",
                        top:
                            String(
                                (timeToNumber(
                                    dayjs(event.date_start).format("HH:mm")
                                ) *
                                    450) /
                                    540
                            ) + "px",
                        height:
                            String(
                                (timeToNumber(
                                    dayjs(event.date_end).format("HH:mm")
                                ) *
                                    450) /
                                    540 -
                                    (timeToNumber(
                                        dayjs(event.date_start).format("HH:mm")
                                    ) *
                                        450) /
                                        540
                            ) + "px",
                            width: event.width ? `${event.width}%` : '100%',
                            left: event.leftOffset ? `${event.leftOffset}%` : '0%',
                    }}
                    onClick={() => setDetailedEvent(event)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    key={i}
                >
                    <div className="week-event-time week-event-text" style={{
                        // marginTop: String(event.intersect_in_hour ? String(event.intersect_querry * 40) + "px" : "0px")
                    }}>
                        {dayjs(event.date_start).format("HH:mm")}
                    </div>
                    <div className="week-event-name week-event-text">
                        {event.name}
                    </div>
                    <div className="week-event-place week-event-text">
                        {event.place}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default WeekDay;
