import React, { useState, useEffect, useContext, useRef } from "react";
import Navbar from "../components/Navbar";
import MyButton from "../components/UI/button/MyButton";
import { API_AUTH_URL, ORGANIZATION_NAME } from "../config";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import MyLoader from "../components/UI/loader/MyLoader";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import DeleteEventConfirm from "../components/DeleteEventConfirm";
import { getEventsDate } from "../utils/createEventsDate";

const EventsPage = () => {
    const [eventsType, setEventsType] = useState(-1);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [userEvents, setUserEvents] = useState([]);
    const [isDeletingEvent, setDeletingEvent] = useState(false);

    const {
        authTokens,
        loading,
        setLoading,
        setGeneralError,
        updateToken,
        generalError,
        closeAlert,
        isRefreshingToken,
        setIsRefreshingToken,
    } = useContext(AuthContext);

    useEffect(() => {
        document.title = "Мои события | " + ORGANIZATION_NAME
      }, [])

    const stillConfirming = useRef(null);
    const returned = useRef(null);
    const confirmed = useRef(null);
    const archived = useRef(null);

    const EventTypeClick = (
        thisButton,
        firstOther,
        secondOther,
        thirdOther
    ) => {
        if (thisButton.current.id === "id1") {
            setEventsType(-1);
        } else if (thisButton.current.id === "id2") {
            setEventsType(0);
        } else if (thisButton.current.id === "id3") {
            setEventsType(1);
        } else if (thisButton.current.id === "id4") {
            setEventsType(3);
        }
        const thisObject = thisButton.current;
        thisObject.classList.add("MyButton_active__asfJN");
        const firstObject1 = firstOther.current;
        firstObject1.classList.remove("MyButton_active__asfJN");
        const secondObject = secondOther.current;
        secondObject.classList.remove("MyButton_active__asfJN");
        const thirdObject = thirdOther.current;
        thirdObject.classList.remove("MyButton_active__asfJN");
    };

    const getUserEventsData = async () => {
        setLoading(true);
        try {
            const res = await axios({
                url: `${API_AUTH_URL}/api/v1/events/`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "ru",
                    Authorization: `Bearer ${authTokens.access_token}`,
                },
                params: {
                    start: getEventsDate()[0],
                    end: getEventsDate()[1],
                    user_created: true,
                },
            });
            setUserEvents(res.data);
        } catch (e) {
            console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await getUserEventsData();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
        }
    };

    useEffect(() => {
        getUserEventsData();
        const body = document.getElementsByClassName("App")[0]
        body.classList.remove("lock")
    }, []);

    useEffect(() => {
        if (eventsType === 3) {
            setFilteredEvents(userEvents.filter(item => dayjs(item.date_start).isBefore(dayjs(), 'day')))
        } else if (eventsType === 1) {
            setFilteredEvents(userEvents.filter(item => item.status === eventsType).filter(item => dayjs(item.date_start).isAfter(dayjs(), 'day')));
        } else {
            setFilteredEvents(userEvents.filter(item => item.status === eventsType));
        }
        setTimeout(() => {
            setLoading(false);
        }, 100)

    }, [eventsType, userEvents]);

    useEffect(() => {
        if (!loading) {
            const stillConfirming1 = stillConfirming.current;
            stillConfirming1.classList.add("MyButton_active__asfJN");
        }
    }, [loading]);

    const deleteEvent = async (eventId) => {
        setLoading(true);
        try {
            const res = await axios({
                url: `${API_AUTH_URL}/api/v1/events/${eventId}/`,
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "ru",
                    Authorization: `Bearer ${authTokens.access_token}`,
                }
            });
            // await postCommentToEvent(res.data.id);
            setGeneralError(['111', 'Мероприятие успешно удалено']);
            setDeletingEvent(false);
            await getUserEventsData();
            setLoading(false);
            } catch (e) {
            console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await deleteEvent(eventId);
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data[0]) {
                setGeneralError([e.response.status, e.response.data[0]]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
        }
    };

    
    return (
        <div>
            <Navbar />
            <div className="main">
                {loading ? (
                    <MyLoader />
                ) : (
                    <div className="list-events-block">
                        <div className="page-name-block form-item-title">
                            Мои мероприятия
                        </div>
                        <div className="events-type-chosing">
                            <MyButton
                                innerRef={stillConfirming}
                                width={"208px"}
                                id="id1"
                                onClick={() =>
                                    EventTypeClick(
                                        stillConfirming,
                                        returned,
                                        confirmed,
                                        archived
                                    )
                                }
                            >
                                На рассмотрении
                            </MyButton>
                            <MyButton
                                innerRef={returned}
                                width={"208px"}
                                id="id2"
                                onClick={() =>
                                    EventTypeClick(
                                        returned,
                                        stillConfirming,
                                        confirmed,
                                        archived
                                    )
                                }
                            >
                                Возвращено
                            </MyButton>
                            <MyButton
                                innerRef={confirmed}
                                width={"208px"}
                                id="id3"
                                onClick={() =>
                                    EventTypeClick(
                                        confirmed,
                                        returned,
                                        stillConfirming,
                                        archived
                                    )
                                }
                            >
                                Согласовано
                            </MyButton>
                            <MyButton
                                innerRef={archived}
                                width={"208px"}
                                id="id4"
                                onClick={() =>
                                    EventTypeClick(
                                        archived,
                                        returned,
                                        confirmed,
                                        stillConfirming
                                    )
                                }
                            >
                                Архив
                            </MyButton>
                        </div>
                        { filteredEvents.length > 0 ?
                        <div className="events-list-block" key={filteredEvents}>
                            {filteredEvents.map((event) => (
                                <div className="one-event-block" key={event.id}>
                                    <Link
                                        to={"/event/" + event.id}
                                        className={"event-link"}
                                        key={event.id}
                                        onClick={() => setLoading(true)}
                                    >
                                        <div className="one-event-info">
                                            <div className="one-event-name">
                                                {event.name}
                                            </div>
                                            <div className="one-event-date">
                                                {dayjs(event.date_start).format(
                                                    "DD.MM.YYYY"
                                                ) ===
                                                dayjs(event.date_end).format(
                                                    "DD.MM.YYYY"
                                                )
                                                    ? dayjs(
                                                          event.date_start
                                                      ).format("DD.MM.YYYY")
                                                    : dayjs(
                                                          event.date_start
                                                      ).format("DD.MM.YYYY") +
                                                      " - " +
                                                      dayjs(
                                                          event.date_end
                                                      ).format("DD.MM.YYYY")}
                                            </div>
                                            <div className="one-event-time">
                                                {dayjs(event.date_start).format(
                                                    "HH:mm"
                                                )}{" "}
                                                -{" "}
                                                {dayjs(event.date_end).format(
                                                    "HH:mm"
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                    {eventsType < 2 && (
                                        <div className="one-event-delete">
                                            <i
                                                className="material-icons delete-event-icon"
                                                onClick={() =>
                                                    setDeletingEvent(event)
                                                }
                                            >
                                                delete
                                            </i>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div> : <div className="no-events-block">Здесь пока нет мероприятий</div>}
                    </div>
                )}
            </div>
            {isDeletingEvent && (
                <DeleteEventConfirm
                    eventName={isDeletingEvent.name}
                    closeMessage={() => setDeletingEvent(false)}
                    deleteEvent={() => deleteEvent(isDeletingEvent.id)}
                />
            )}
        </div>
    );
};

export default EventsPage;
