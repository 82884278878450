import React, { useState, useEffect, useContext, useRef } from "react";
import Navbar from "../components/Navbar";
import MyButton from "../components/UI/button/MyButton";
import { API_AUTH_URL, ORGANIZATION_NAME } from "../config";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import MyLoader from "../components/UI/loader/MyLoader";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import DeleteEventConfirm from "../components/DeleteEventConfirm";
import { getEventsDate } from "../utils/createEventsDate";
import MyTextarea from "../components/UI/textarea/MyTextarea";
import SuccessApproved from "../components/SuccessApproved";

const ConfirmEventPage = () => {
    const [wholeEvent, setEvent] = useState("");
    const [comment, setComment] = useState("");
    const [isConfirmed, setConfirmed] = useState(false);

    const [successApproved, setSuccessApproved] = useState(false);

    const {
        authTokens,
        loading,
        setLoading,
        setGeneralError,
        updateToken,
        generalError,
        closeAlert,
        isRefreshingToken,
        setIsRefreshingToken,
    } = useContext(AuthContext);

    useEffect(() => {
        document.title = "Cогласование | " + ORGANIZATION_NAME;
    }, []);

    const [eventId, setEventId] = useState(
        window.location.pathname.split("/")[2]
    );

    const getDetailedEvent = async () => {
        setLoading(true);
        try {
            const res = await axios({
                url: `${API_AUTH_URL}/api/v1/events/${eventId}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "ru",
                    Authorization: `Bearer ${authTokens.access_token}`,
                },
            });
            setEvent(res.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setLoading(true);
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await getDetailedEvent();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
            setLoading(false);
        }
    };

    const postCommentToEvent = async (eventId) => {
        try {
            const res = await axios({
                url: `${API_AUTH_URL}/api/v1/comments/`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "ru",
                    Authorization: `Bearer ${authTokens.access_token}`,
                },
                data: {
                    text: comment === "" ? "false" : comment,
                    event: eventId,
                },
            });
        } catch (e) {
            console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await postCommentToEvent();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data[0]) {
                setGeneralError([e.response.status, e.response.data[0]]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
            setLoading(false);
        }
    };

    const makeApproval = async (approval_status) => {
        setLoading(true);
        try {
            const res = await axios({
                url: `${API_AUTH_URL}/api/v1/approvals/`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "ru",
                    Authorization: `Bearer ${authTokens.access_token}`,
                },
                data: {
                    event: eventId,
                    status: approval_status,
                },
            });
            if (comment.length > 0 && comment !== "") {
                await postCommentToEvent(eventId);
            }
            await setSuccessApproved(true);
            setLoading(false);
        } catch (e) {
            console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setLoading(true);
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await makeApproval(approval_status);
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
            setLoading(false);
        }
    };

    const approveThis = async () => {
        makeApproval(1);
        setConfirmed(true);
    };

    const rejectThis = async () => {
        makeApproval(0);
        setConfirmed(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            await getDetailedEvent();
        };
        fetchData();
        const body = document.getElementsByClassName("App")[0];
        body.classList.remove("lock");
    }, []);

    return (
        <div>
            <Navbar />
            <div className="main">
                {loading ? (
                    <MyLoader />
                ) : (
                    <div className="list-events-block list-events-block-confirming">
                        <div className="page-name-block form-item-title">
                            Мероприятие {wholeEvent.name}
                        </div>
                        <div className="approval-event-info">
                            <div className="detailed-event-body">
                                {wholeEvent.klasses && (
                                    <div className="detailed-audience">
                                        {wholeEvent.klasses.map((item) => (
                                            <div
                                                className="detailed-audience-item"
                                                key={item.id}
                                            >
                                                {item.parallel + item.letter}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <div className="detailed-vr">
                                    <a className="detailed-bold-names">
                                        Тип мероприятия:
                                    </a>{" "}
                                    {wholeEvent.type.name}
                                </div>
                                <div className="detailed-date">
                                    <a className="detailed-bold-names">
                                        Дата проведения:
                                    </a>{" "}
                                    {dayjs(wholeEvent.date_start).format(
                                        "DD.MM.YYYY"
                                    ) ===
                                    dayjs(wholeEvent.date_end).format(
                                        "DD.MM.YYYY"
                                    )
                                        ? dayjs(wholeEvent.date_start).format(
                                              "DD.MM.YYYY"
                                          )
                                        : dayjs(wholeEvent.date_start).format(
                                              "DD.MM.YYYY"
                                          ) +
                                          " - " +
                                          dayjs(wholeEvent.date_end).format(
                                              "DD.MM.YYYY"
                                          )}
                                </div>{" "}
                                {/* TODO: parse and display date with dayjs */}
                                <div className="detailed-time">
                                    <a className="detailed-bold-names">
                                        Время:
                                    </a>{" "}
                                    {dayjs(wholeEvent.date_start).format(
                                        "HH:mm"
                                    )}{" "}
                                    -{" "}
                                    {dayjs(wholeEvent.date_end).format("HH:mm")}
                                </div>
                                <div className="detailed-place">
                                    <a className="detailed-bold-names">
                                        Место проведения:
                                    </a>{" "}
                                    {wholeEvent.place}
                                </div>
                                <div className="detailed-vr">
                                    <a className="detailed-bold-names">
                                        Модуль ВР:
                                    </a>{" "}
                                    {wholeEvent.module.name}
                                </div>
                                <div className="detailed-external">
                                    <a className="detailed-bold-names">
                                        Внешнее/внутреннее:
                                    </a>{" "}
                                    {wholeEvent.is_external
                                        ? "Внешнее"
                                        : "Внутреннее"}
                                </div>
                                {wholeEvent.is_external &&
                                    <div className="detailed-vr">
                                        <a className="detailed-bold-names">
                                            Организатор:
                                        </a>{" "}
                                        {wholeEvent.organizer.name}
                                    </div>
                                }
                                {wholeEvent.linked_document && (
                                    <>
                                        {wholeEvent.linked_document.length >
                                            0 && (
                                            <div className="detailed-vr">
                                                <a className="detailed-bold-names">
                                                    Прикрепленная ссылка:
                                                </a>
                                                {<p></p>}
                                                <a
                                                    href={
                                                        wholeEvent.linked_document
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {wholeEvent.linked_document}
                                                </a>
                                            </div>
                                        )}
                                    </>
                                )}
                                {wholeEvent.additional_info && (
                                    <>
                                        {wholeEvent.additional_info.length >
                                            0 && (
                                            <div className="detailed-comment">
                                                <a className="detailed-bold-names">
                                                    Дополнительная информация:
                                                </a>
                                                {<p></p>}
                                                {wholeEvent.additional_info}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                            <div className="form-comments-block-for-approval">
                                <div className="form-one-item">
                                    <div className="form-item-title">
                                        Процесс согласования
                                    </div>
                                    <div className="approval-steps-title">
                                        Этапы согласования:
                                    </div>
                                    <div className="form-item-approval-steps">
                                        {wholeEvent.approval &&
                                            wholeEvent.approval.map((item) => (
                                                <div className="form-approval-one-item">
                                                    <div
                                                        className="approval-item-dot"
                                                        style={
                                                            item.status === -1
                                                                ? {
                                                                      "background-color":
                                                                          "#939397",
                                                                  }
                                                                : {
                                                                      "background-color":
                                                                          item.status ===
                                                                          0
                                                                              ? "#c30000"
                                                                              : "#00c100",
                                                                  }
                                                        }
                                                    ></div>
                                                    <div className="approval-item-name">
                                                        {item.role}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="approval-steps-title-2">
                                        Комментарии согласующих:
                                    </div>
                                    <div className="form-item-comments-list">
                                        {wholeEvent.comments.filter(
                                            (item) => item.text !== "false"
                                        ).length > 0 ? (
                                            wholeEvent.comments
                                                .filter(
                                                    (item) =>
                                                        item.text !== "false"
                                                )
                                                .map((item, index) => (
                                                    <div
                                                        className="form-item-comment-one"
                                                        key={item.id}
                                                    >
                                                        {index + 1}. {item.text}{" "}
                                                        ({item.created_by})
                                                    </div>
                                                ))
                                        ) : (
                                            <div className="form-item-text-no">
                                                Пока комментариев нет
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="detailed-event-approval">
                                <div className="approval-comment">
                                    <div className="form-comment-block">
                                        <div className="date-text">
                                            Добавьте рекомендации по доработке
                                            мероприятия, замечания и указания:
                                        </div>
                                        <MyTextarea
                                            value={comment}
                                            onChange={(e) =>
                                                setComment(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="approval-actions">
                                    <MyButton onClick={() => approveThis()}>
                                        Согласовать
                                    </MyButton>
                                    <MyButton onClick={() => rejectThis()}>
                                        Вернуть в доработку
                                    </MyButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {successApproved && (
                <SuccessApproved
                    closeMessage={() => setSuccessApproved(false)}
                    isConfirmed={isConfirmed}
                />
            )}
        </div>
    );
};

export default ConfirmEventPage;
