import React, {useContext} from 'react';
import MonthDay from './MonthDay';
import CalendarContext from '../../context/CalendarContext';

const Month = ({month, monthIndex, setCalendarType, dayClick, weekClick, makeDetailed, setEventToDetail}) => {

    const {weekIndex, setWeekIndex, setYearIndex, yearIndex} = useContext(CalendarContext);

    const moveToWeek = (weekInd) => {
        setYearIndex(weekInd.year());
        if (monthIndex < 0) {
            setWeekIndex(weekInd.week()+1);
        } else {
            setWeekIndex(weekInd.week());
        }
        setCalendarType(0)
        weekClick()
    }

    return (
        <>
        {month.length === 6 ? (
        <div className="week-toggle-block-6">
            <div className="div-for-week-toggle week-toggle-1" onClick={() => moveToWeek(month[0][0])}>{""}</div>
            <div className="div-for-week-toggle week-toggle-2" onClick={() => moveToWeek(month[1][0])}>{""}</div>
            <div className="div-for-week-toggle week-toggle-3" onClick={() => moveToWeek(month[2][0])}>{""}</div>
            <div className="div-for-week-toggle week-toggle-4" onClick={() => moveToWeek(month[3][0])}>{""}</div>
            <div className="div-for-week-toggle week-toggle-5" onClick={() => moveToWeek(month[4][0])}>{""}</div>
            <div className="div-for-week-toggle week-toggle-6" onClick={() => moveToWeek(month[5][0])}>{""}</div>
        </div>) : (
        <div className="week-toggle-block-5">
            <div className="div-for-week-toggle week-toggle-1" onClick={() => moveToWeek(month[0][0])}>{""}</div>
            <div className="div-for-week-toggle week-toggle-2" onClick={() => moveToWeek(month[1][0])}>{""}</div>
            <div className="div-for-week-toggle week-toggle-3" onClick={() => moveToWeek(month[2][0])}>{""}</div>
            <div className="div-for-week-toggle week-toggle-4" onClick={() => moveToWeek(month[3][0])}>{""}</div>
            <div className="div-for-week-toggle week-toggle-5" onClick={() => moveToWeek(month[4][0])}>{""}</div>
        </div>)}
        <div className="month-calendar-body">
            <div className="month-header-table">
                <div className="month-header-table-cell">ПН</div>
                <div className="month-header-table-cell">ВТ</div>
                <div className="month-header-table-cell">СР</div>
                <div className="month-header-table-cell">ЧТ</div>
                <div className="month-header-table-cell">ПТ</div>
                <div className="month-header-table-cell">СБ</div>
                <div className="month-header-table-cell">ВС</div>
            </div>
            <div className={month.length === 5 ? 'one-month-table' : `one-month-table-6`}>
                {month.map((row, i) => (
                    <React.Fragment key={i}>
                        {row.map((day, idx) => (
                            <MonthDay day={day} rowIdx={i} key={idx} monthIndex={monthIndex} setCalendarType={setCalendarType} dayClick={dayClick} makeDetailed={makeDetailed} setEventToDetail={setEventToDetail}/>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
        </>
    );
}

export default Month;
